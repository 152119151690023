import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call } from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    list: [],
};

export const EducationalCredits = createSlice({
    name: "EducationalCredits",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
    },
});

export const { setPagination, setList, clearPagination } =
    EducationalCredits.actions;

export default EducationalCredits.reducer;

// Actions
export const startGetEducationalCredits = ({
    page = 1,
    query = "",
    number_rows = 10,
    asList = 0,
    cycles,
    coordination_ids,
    curriculum_ids,
    status,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
        }

        params.set("cycles", `${cycles?.year}-${cycles?.sub_cycle}`);

        if (coordination_ids?.length) {
            params.set(
                "coordination_ids",
                coordination_ids.map((item) => item.value).join(",")
            );
        }

        if (curriculum_ids?.length) {
            params.set(
                "curriculum_ids",
                curriculum_ids.map((item) => item.value).join(",")
            );
        }

        if (status?.length) {
            params.set("status", status.map((item) => item.value).join(","));
        }

        const resp = await Call(
            `operators/educational-credits/show`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startCreateRequest = (values, setErrors, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/educational-credits/create-request`,
            "POST",
            values
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Solicitud de crédito educativo registrada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
